html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter" !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
 
}

.menu__container .MuiPaper-root {
  left: 0px !important;
  top: 60px !important;
  width: 100%;
  box-shadow: none !important;
  height: 100%;
  max-width: 100% !important;
}

.local__dropdown .MuiPaper-root {
  max-height: 190px !important;
  overflow: scroll;
  overflow-x: hidden;
}

.local__dropdown .MuiPaper-root::-webkit-scrollbar {
  width: 12px;
}

.local__dropdown .MuiPaper-root::-webkit-scrollbar-track {
  background: #ffffff;
}

.local__dropdown .MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

.createMemory .MuiStepper-root {
  display: none;
}

.tags__textfield .MuiFilledInput-root {
  padding: 4px !important;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.tags__textfield .css-1w8yruj-MuiInputBase-root-MuiFilledInput-root:after {
  border: none;
  outline: none;
}

.tags__textfield.css-1w8yruj-MuiInputBase-root-MuiFilledInput-root:before {
  border: none;
}

.tags__textfield .MuiFilledInput-root:hover {
  background: none;
}

/* .tags__textfield .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding-right: 5px !important;
} */
.tags__textfield .MuiInputBase-root {
  padding-right: 5px !important;
}

.tags__textfield .MuiOutlinedInput-root {
  padding-right: 5px !important;
}

.custom__datepicker .MuiFormControl-root {
  width: 100%;
}

.custom__datepicker label {
  display: none;
  top: 50% !important;
  left: 11% !important;
}

.custom__datepicker legend {
  display: none;
}

.custom__datepicker .MuiInputBase-root {
  /* flex-direction: row-reverse; */
}

.custom__datepicker input {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #667085 !important;
  height: 44px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 14px !important;
}

.custom__datepicker .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  padding-left: 0px !important;
}

.custom__switchBtn {
  margin-left: 12px !important;
}

.custom__switchBtn label .MuiTypography-root {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #344054 !important;
  margin-left: 10px !important;
}

/* .custom__switchBtn .MuiSwitch-switchBase {
  color: #ffffff !important;
}
.custom__switchBtn .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
   padding: 12px 9px !important;
}
.custom__switchBtn .MuiSwitch-track{
  background: #F62E8E !important;
  opacity: 1 !important;
}
.custom__switchBtn .css-julti5-MuiSwitch-root{
  height: 40px !important;
}
.custom__switchBtn .css-jsexje-MuiSwitch-thumb{
  width: 15px !important;
    height: 15px !important;
}


.custom__switchBtn .MuiSwitch-track {
  background: #F62E8E !important;
  opacity: 1 !important;
} */

.tags_input .MuiFilledInput-root{
  background:#ffffff !important;
}
.tags_input .css-1w8yruj-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 0px !important;
}
.tags_input .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
   display: none;
}
.tags_input .css-187ruhz-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 0px !important;
}

.tags_input
  .css-187ruhz-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 0px !important;
}

.tags_input
  .css-1w8yruj-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 0px !important;
}

.search__autoComplete {
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-left: 16px;
  background-color: #ffffff !important;
}

.search__autoComplete label {
  display: none;
}
.search__autoComplete input {
  width:100%;
}
.search__autoComplete legend {
  display: none;
}

.search__autoComplete fieldset {
  border: none !important;
}

.search__autoComplete .MuiOutlinedInput-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.search__autoComplete .MuiAutocomplete-endAdornment {
  visibility: hidden;
  display: none;
  right: -20px !important;
}

.search__autoComplete input {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #667085 !important;
  height: 44px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* for new style */
  margin-left: 10px !important;
  border: 0px !important;
}

.search__autoComplete input::placeholder {
  color: #a9a9a9 !important;
}

.search__autoComplete input:focus {
  outline: none;
}

.memory_search .MuiAutocomplete-popper {
  width: 330px !important;
  transform: translate3d(0px, 50px, 0px) !important;
}

.search__autoComplete .MuiListItemAvatar-root {
  min-width: auto !important;
  margin-right: 10px !important;
}

.search__autoComplete .MuiTypography-body1 {
  max-width: 70% !important;
}

.search__autoComplet .MuiOutlinedInput-root {
  padding-right: 0px !important;
}

/* carousel styling */
.createMemory__slider .swiper-wrapper {
  min-height: 300px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-pagination-bullets {
    background: transparent !important;
}
.swiper-pagination-bullet {
    width: 6px;
    height:6px
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide img {
  max-height: 640px;  
}

.createMemory__slider .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px !important;
  color: #ffffff;
}
.default__carousel .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px !important;
  color: #ffffff;
}
.default__carousel .swiper-button-next,
.swiper-button-prev {
     height: 30px;
     width:30px;
     border-radius: 100%;
     background: rgba(0, 0, 0, 0.9);
}
.createMemory__slider .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.createMemory__slider .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.createMemory__slider .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  background: rgba(0, 0, 0, 0.7);
  width: max-content;
  left: 50%;
  padding: 0px 8px;
  border-radius: 12px;
  bottom: 12px;
  transform: translateX(-50%);
}
.createMemory__slider .swiper-pagination-bullet {
  background: #e8e9e8 !important;
  opacity: 0.7;
}
.default__carousel .swiper-pagination-bullet {
  background: #e8e9e8 !important;
  opacity: 0.7;
}
.createMemory__slider .swiper-pagination-bullet-active {
  background: #ffffff !important;
  opacity: 1;
}
.default__carousel .swiper-pagination-bullet-active {
  background: #ffffff !important;
  opacity: 1;
}
/* .createMemory__slider .swiper-pagination-bullet {
  background: #ffffff !important;
} */

.centerLayout {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.rightLayout {
  position: fixed;
  height: 100vh;
  max-width: 100%;
}

.socialicons {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  cursor: pointer;
}

.socialicons > li {
  padding-right: 10px;
}

.footermenu {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0px;
  margin: 5px 0px;
}

.footermenu > li {
  padding-right: 10px;
  color: #98a2b3;
  font-size: 12px;
  font-weight: 500;
  font-family: Inter;
}

.footerBrand {
  color: #98a2b3;
  font-size: 12px;
  font-weight: 500;
  margin: 15px 0px;
}

.postStatusicons {
  list-style-type: none;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.postStatusicons > li {
  padding-right: 10px;
}

.seeAllCursor {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.warning {
  padding: 4px 3px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.memoryCarousel__container .css-1m9128y {
  position: absolute;
  bottom: 11px;
  background-color: rgba(0, 0, 0, 0.7) !important;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  width: fit-content;
  margin: 0px auto;
  margin-left: 43%;
  padding: 0px 10px;
  border-radius: 50px;
  z-index: 1;
  display: flex !important;
  column-gap: 12px;
  height: 20px;
}
.memoryCarousel__container > .css-1m9128y > button > svg {
  width:8px;
  height:8px
}
.memoryCarousel__container .css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
  color: #ffffff
}
.cookie_btn_wrapper {
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
}
/* phone input */
.PhoneInput {
  border:1px solid #d0d5dd;
  padding:8px 12px;
  border-radius: 8px;
  height: 40px;
}
.PhoneInputInput {
  border:none;
  color:#667085;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}
.PhoneInput--focus input {
  border:none !important;
  outline: none;

}
.PhoneInputCountry {
  margin-top: -3px;
}
.phoneInputWithCountrySelectCountry{
  color:#101828;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
 
}
::selection {
  color: none;
  background: none;
}
/* For Mozilla Firefox */
::-moz-selection {
  color: none;
  background: none;
}
/* Enable text selection for specific elements */
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
input::selection {
  color: #fff !important;
  background: rgb(246, 46, 142) !important;
}


/* media queries */
@media screen and (max-width: 480px) {
  .swiper-slide img {
    height: 300px;
    /* width: auto; */
  }

  .createMemory__slider .swiper-wrapper {
    height: 300px !important;
  }

  .createMemory__slider .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 12px;
  }

  .plr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mt-0 {
    margin-top: 0px !important;
  }

  #memory-details-mob-hide {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .totalUsers {
    display: none;
  }
  .default__search  .css-hyum1k-MuiToolbar-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
 }
}

@media screen and (max-width: 992px) {
  .rightLayout {
    display: none;
  }
  .right-layout-timer {
    display: block !important;
  }
}

@media screen and (min-width: 768px) {
  .center-card {
    width: 38rem;
  }
  #memory-details-desktop-hide {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1336px) {
  .right-card {
    margin: 0px 10px;
  }
}
@media screen and (max-width: 768px){
 
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.root_container,
.root_scroll {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.root_container {
    overflow: hidden;
}
.root_scroll {
    padding-right: 20px;
    right: -20px;
    overflow-y: scroll;
}

}